import fetch from '@/utils/fetch.js';

//banner图
export function bannerList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/banner/list",
		method: 'POST',
		data
	})
}
//banner点击
export function bannerClick(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/banner/click",
		method: 'POST',
		data
	})
}
//新闻公告
export function newsList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/news/list",
		method: 'POST',
		data
	})
}
//问答管理
export function qaList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/qa/list",
		method: 'POST',
		data
	})
}
//消息统计总数量
export function noticeCount(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/notice/count",
		method: 'POST',
		data
	})
}
//常规消息列表
export function noticeList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/notice/list",
		method: 'POST',
		data
	})
}
//设置消息已读
export function setRead(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/content/notice/set/read",
		method: 'POST',
		data
	})
}